<template>
  <div>
    <el-form-item label="描述文案：">
      <el-input v-model="form.ResultDescription" type="textarea" :rows="6" placeholder="最多输入200个字"
       style="width:458px" maxlength="200" show-word-limit></el-input>
    </el-form-item>
    <el-form-item label="Slogan：">
      <div class="flex flex-align-center">
        <el-upload :action="imgApi" accept="image/*" list-type="picture-card" :show-file-list="false"
        :before-upload="beforeupload" :on-success="($event)=>{onsuccess($event,'ResultImgUrl')}">
          <div style="width:100%;overflow-y:hidden;height:100%" v-if="form.ResultImgUrl">
            <img :src="imgUrl+form.ResultImgUrl" style="width:100%" alt="">
          </div>
          <i class="el-icon-plus" v-else></i>
        </el-upload>
        <el-button type="text" class="margin-left-10" v-if="form.ResultImgUrl" @click="onsuccess([],'ResultImgUrl')" style="margin-top:auto;">清空</el-button>
      </div>
      <div class="font-14" style="color:#999999FF">Slogan图片建议尺寸宽670px，高120x；图片大小1M以内</div>
    </el-form-item>
  </div>
</template>

<script>
import config from '@/config/index';
export default {
  props: {
    form:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    rules:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  data () {
    return {
      imgApi: config.UPLOAD_IMG,
      imgUrl: config.IMG_BASE,

    }
  },
  methods: {
    onsuccess(file,key){
      this.$emit('change',{
        key:key,
        value:file[0]
      })
    },
    beforeupload(file){
      const size = file.size / 1024 / 1024 < 1;
      const testtype = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg']
      if (testtype.indexOf(file.type) == -1) {
        this.$message.error('请选择图片上传');
        return false
      } else if (!size) {
        this.$message.error('图片大小请控制在1M以内');
        return false
      }
    },
  }
}
</script>

<style lang='less' scoped>

</style>