<template>
  <div>
    <el-card style="margin-bottom:80px" v-loading="loading">
      <div class="tipsPanel">
        <div class="textBody" :class="{'text-409':tipIndex==1}">
          <div class="flex-1">1.基本信息设置</div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="textBody" :class="{'text-409':tipIndex==2}">
          <div class="flex-1">2.题目设置</div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="textBody" :class="{'text-409':tipIndex==3}">
          <div class="flex-1">3.结果页设置</div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="flex margin-top-20">
        <div class="margin-right-20">
          <div class="phoneBox" v-if="tipIndex==1||tipIndex==3">
            <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-phone.png" style="width:375px" alt="">
            <div class="content">
              <div class="header ellipsis">{{form.Name||'测一测主题名称'}}</div>
              <div class="main" v-if="tipIndex==1">
                <div style="text-align:center;margin-top:83px" v-if="!form.CoverImgUrl">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-default.png" style="width:96px" alt="">
                  <div class="font-16 margin-top-10" style="color:#909399FF">测一测封面显示区域</div>
                </div>
                <div class="buttonBox">
                  <img :src="imgUrl+form.CoverImgUrl" alt="">
                  <div class="testButton">开始测试</div>
                </div>
              </div>
              <div class="main" v-if="tipIndex==3">
                <div class="backdrop">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-backdrop.png" style="width:100%" alt="">
                  <div class="result">
                    <div class="flex flex-align-center" style="margin:34px 0px 0px 27px">
                      <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-arrow.png" style="width:17px" alt="">
                      <div class="res-font">测试结果分析</div>
                    </div>
                    <div class="copywriting">
                      {{form.ResultDescription||'此处显示描述文案'}}
                    </div>
                    <div class="catchword">
                      <div v-if="form.ResultImgUrl">
                        <img :src="imgUrl+form.ResultImgUrl" style="width:100%" alt="">
                      </div>
                      <div class=" flex flex-align-center flex-justify-center" v-else>
                        <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-default.png" style="width:46px" alt="">
                        <div class="font-14 margin-left-10" style="color:#909399FF">Slogan图片展示区域</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <el-form :model="form" :rules="rules" label-width="120px" ref="form">
            <tips1 :form="form" :rules="rules" @change="({key,value})=>{form[key]=value}" v-if="tipIndex==1"></tips1>
            <tips2 :form="form" :rules="rules" @change="({key,value})=>{form[key]=value}" v-if="tipIndex==2"></tips2>
            <tips3 :form="form" :rules="rules" @change="({key,value})=>{form[key]=value}" v-if="tipIndex==3"></tips3>
          </el-form>
        </div>
      </div>
    </el-card>
    <el-card class="bottom-card">
      <el-button @click="tipIndex--" v-if="tipIndex>1">上一步</el-button>
      <el-button type="primary" @click="submitForm('form')">{{tipIndex==3?'保存':'下一步'}}</el-button>
    </el-card>
  </div>
</template>

<script>
import config from '@/config/index';
import tips1 from "./components/tips1"
import tips2 from "./components/tips2"
import tips3 from "./components/tips3"
import {
  takeTestadd,
  takeTestinfo,
  takeTestedit
} from "@/api/TurnTomySelf"
export default {
  components:{
    tips1,
    tips2,
    tips3
  },
  data () {
    var checkProductAttributeId = (rule, value, callback) => {
      // console.log(this.form.ProductRangeType)
      if(this.form.ProductRangeType==2){
        console.log(value,typeof [value])
        if(typeof value == 'object'){
          if(!value||!value.length){
            callback(new Error('请选择商品属性'))
          }else{
            callback()
          }
        }else{
          if(!value){
            callback(new Error('请选择商品属性'))
          }else{
            callback()
          }
        }
        
      }else{
        callback()
      }
    };
    var checkprice = (rule, value, callback) => {
      let i = rule.field.split('.')[1]
      let y = rule.field.split('.')[3]
      let min = Number(this.form.TakeTestQuestionInfoList[i].TakeTestQuestionOptionInfoList[y].MinPrice)
      let max = Number(this.form.TakeTestQuestionInfoList[i].TakeTestQuestionOptionInfoList[y].MaxPrice)
      if(this.form.TakeTestQuestionInfoList[i].TakeTestQuestionOptionInfoList[y].MinPrice===''){
        callback(new Error('最小价格不可为空'))
      }else if(min<0||max<0){
        callback(new Error('请输入正确的价格'))
      }else if(min>100000000||max>100000000){
        callback(new Error('价格设置过大，请重新设置'))
      }else if(this.form.TakeTestQuestionInfoList[i].TakeTestQuestionOptionInfoList[y].MaxPrice!==''&&min>max){
        // console.log(min,max)
        callback(new Error('最小价格需小于等于最大价格'))
      }else{
        callback()
      }
    };
    return {
      loading:false,
      imgApi: config.UPLOAD_IMG,
      imgUrl: config.IMG_BASE,
      tipIndex:1,
      form:{
        Id:0,
        Name:'',
        ProductRangeType:1,
        ProductAttributeId:'',
        CoverImgUrl:'',
        TakeTestQuestionInfoList:[],
        ResultDescription:'',
        ResultImgUrl:'',
      },
      rules:{
        Name:{
          required: true, message: '请输入测一测主题', trigger: 'blur'
        },
        ProductAttributeId:{
          validator: checkProductAttributeId, trigger: 'blur'
        },
        CoverImgUrl:{
          required: true, message: '请上传封面图', trigger: 'blur'
        },
        QName:{
          required: true, message: '请输入题目名称', trigger: 'blur'
        },
        ImgUrl:{
          required: true, message: '请上传选项图片', trigger: 'blur'
        },
        OName:{
          required: true, message: '请输入选项内容', trigger: 'blur'
        },
        price:{
          validator: checkprice, trigger: 'blur'
        },
      }
    }
  },
  mounted () {
    if(this.$route.query.Id){
      this.getinfo()
    }
  },
  methods: {
    async getinfo(){
      try{
        this.loading = true
        let res = await takeTestinfo({
          Id:this.$route.query.Id
        })
        if(res.IsSuccess){
          let form = res.Result
          form.TakeTestQuestionInfoList = form.TakeTestQuestionInfoList.map(v=>{
            v.TakeTestQuestionOptionInfoList = v.TakeTestQuestionOptionInfoList.map(x=>{
              x.ProductAttributeIdListAll = []
              return x
            })
            v.show = false
            return v
          })
          this.form = form
        }
      }finally{
        this.loading = false
      }
    },
    submitForm(formName) {
      if(this.tipIndex==2&&(!this.form.TakeTestQuestionInfoList||!this.form.TakeTestQuestionInfoList.length)){
        this.$message.error('请完善步骤2配置')
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.tipIndex<3){
            console.log(this.form.ProductAttributeId)
            this.tipIndex+=1
            return
          }
          this.save()
        } else {
          this.$message.error(`请完善步骤${this.tipIndex}配置`)
          this.$nextTick(()=>{
            const element = document.querySelectorAll('.el-form-item__error')[0] 
            // 滚动到错误元素对应位置 
            element.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center' 
            }) 
          })
          return false;
        }
      });
    },
    async save(){
      try{
        this.loading = true
        let form = this.amend()
        let res
        if(form.Id){
          res=  await takeTestedit({TakeTestInfo:form})
        }else{
          res = await takeTestadd({TakeTestInfo:form})
        }
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.$router.go(-1)
        }
      }finally{
        this.loading = false
      }
    },
    amend(){
      let form = JSON.parse(JSON.stringify(this.form))
      if(form.ProductRangeType==1){
        form.ProductAttributeId  = ''
        form.ProductAttributeDescription  = ''
      }else{
        if(Array.isArray(form.ProductAttributeId)){
          form.ProductAttributeId = form.ProductAttributeId[form.ProductAttributeId.length-1]
        }
      }
      console.log(form.TakeTestQuestionInfoList)
      form.TakeTestQuestionInfoList.map((v,i)=>{
        v.SerialMumber = i
        v.TakeTestQuestionOptionInfoList = v.TakeTestQuestionOptionInfoList.map((x,y)=>{
          x.SerialMumber = y
          x.ProductRangeType = x.ProductRangeType==0?1:x.ProductRangeType
          if(v.OptionType==1){//普通选项
            if(v.OptionFormat==1){//文本
              x.ImgUrl = ''
            }
            x.MinPrice = ''
            x.MaxPrice = ''
          }else{
            x.Name = ''
            x.ImgUrl = ''
            x.ProductRangeType = ''
            x.ProductAttributeIdList = []
          }
          return x
        })
        return v
      })
      return form
    },
  }
}
</script>

<style lang="less" scoped>
.tipsPanel{
  background: #F5F7FA;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px;
  .textBody{
    color: #999999FF;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    flex:1;
    display: flex;
    align-items: center;
    &:last-child i{
      display: none;
    }
    i{
      color: #999999FF !important
    }
  }
  .text-409{
    color: #409eff;
  }
}
.phoneBox{
  position: relative;
  top: 0;
  left: 0;
  .content{
    position: absolute;
    // border: 1px solid black;
    top: 0;left: 0;
    width: 100%;
    height: 100%;
    .header{
      // border:1px solid black;
      height: 20px;
      text-align: center;
      width: 170px;
      margin: 0 auto;
      margin-top: 62px;
    }
    .main{
      // border: 1px solid black;
      height: calc(100% - 125px);
      overflow: hidden;
      width: 355px;
      margin: 0 auto;
      margin-top: 13px;
      position: relative;
      // height: 100%;
      .buttonBox{
        position: absolute;
        left: 0;
        right: 0;
        top: 0px;
        // border: 1px solid red;
        height: 100%;
        img{
          width: 100%;
        }
        .testButton{
          position: absolute;
          bottom: 109px;
          background: #E0746AFF;
          width: 300px;
          height: 54px;
          border-radius: 30px;
          margin-left: 27.5px;
          font-size: 20px;
          color: #FFFFFF;
          line-height: 54px;
          text-align: center;
        }
      }
      .backdrop{
        // border: 1px solid black;
        width: 100%;
        height: 200px;
        position: relative;
        .result{
          position: absolute;
          // border: 1px solid black;
          top: 0;
          left: 0;
          width: 100%;
          .res-font{
            margin-left: 10px;
            font-size: 24px;
            color: #694B41;
            font-weight: bold;
            line-height: 22px;
          }
          .copywriting{
            margin: 25px 44px 64px 34px;
            font-size: 13px;
            font-weight: 500;
            color: #666666;
            line-height: 18px;white-space: pre-wrap;
          }
          .catchword{
            margin: 0px 20px;
            padding: 11px 0px;
            background: #F8F8F8;
          }
        }
      }
    }
  }
}
.bottom-card{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
}
</style>