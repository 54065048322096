<template>
  <div>
    <el-form-item label="测一测主题：" prop="Name">
      <el-input v-model="form.Name" style="width:400px" placeholder="最多输入20个字" maxlength="20"></el-input>
    </el-form-item>
    <el-form-item label="主题推荐商品：" required>
      <div class="fon-14" style="color:#999999FF">比如选择推荐商品范围【款式>项链】，最终测试结果将从所有【项链】商品中筛选并展示</div>
      <!-- <el-radio-group v-model="form.ProductRangeType" class="margin-top-20"> -->
        <el-radio v-model="form.ProductRangeType" :label="1" :disabled="$route.query.Id>0">全部商品</el-radio>
        <div class="flex flex-align-center margin-top-10" style="height:30px">
          <el-radio v-model="form.ProductRangeType" :label="2" :disabled="$route.query.Id>0">指定商品属性</el-radio>
          <el-form-item label-width="0px" prop="ProductAttributeId" v-if="form.ProductRangeType==2">
            <el-cascader v-model="form.ProductAttributeId" :options="options" style="width:300px" filterable @change="getId" popper-class="node-max-width-200"
             :props="{value:'Id',label:'AttributeName',children:'LowerList',emitPath:false,checkStrictly:true ,}" :disabled="$route.query.Id>0"></el-cascader>
          </el-form-item>
        </div>
      <!-- </el-radio-group> -->
    </el-form-item>
    <el-form-item label="测一测封面：" prop="CoverImgUrl">
      <el-upload :action="imgApi" accept="image/*" list-type="picture-card" :show-file-list="false"
       :before-upload="beforeupload" :on-success="($event)=>{onsuccess($event,'CoverImgUrl')}">
        <div style="width:100%;overflow-y:hidden;height:100%" v-if="form.CoverImgUrl">
          <img :src="imgUrl+form.CoverImgUrl" style="width:100%" alt="">
        </div>
        <i class="el-icon-plus" v-else></i>
      </el-upload>
      <div class="font-14" style="color:#999999FF">封面图片建议尺寸宽750px，高1448px；图片大小1M以内</div>
    </el-form-item>

  </div>
</template>

<script>
import config from '@/config/index';
import {
  productAttributeproductAttributeTree
} from "@/api/TurnTomySelf"
export default {
  props: {
    form:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    rules:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  data () {
    return {
      options:[],
      imgApi: config.UPLOAD_IMG,
      imgUrl: config.IMG_BASE,
    }
  },
  mounted () {
    this.getshop()
  },
  methods: {
    getId(ee){
      // console.log(ee,123)
      this.$emit('change',{
        key:'ProductAttributeId',
        value:ee
      })
    },
    async getshop(){
      try{
        let res = await productAttributeproductAttributeTree({
          Keywords:'',
          Skip:0,
          Take:99999
        })
        if(res.IsSuccess){
          this.options = this.getexpanded(res.Result)
        }
      }finally{
        //
      }
    },
    getexpanded(list){
      return list.map(v=>{
        if(!v.LowerList||!v.LowerList.length){
          delete v.LowerList
        }else{
          v.LowerList = this.getexpanded(v.LowerList)
        }
        return v
      })
    },
    onsuccess(file,key){
      this.$emit('change',{
        key:key,
        value:file[0]
      })
    },
    beforeupload(file){
      const size = file.size / 1024 / 1024 < 1;
      const testtype = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg']
      if (testtype.indexOf(file.type) == -1) {
        this.$message.error('请选择图片上传');
        return false
      } else if (!size) {
        this.$message.error('图片大小请控制在1M以内');
        return false
      }
    },

  }
}
</script>

<style lang='less'>
.node-max-width-200{
  .el-cascader-menu__list{
    max-width: 200px;
  }
}
</style>