import { render, staticRenderFns } from "./tips2.vue?vue&type=template&id=144b78e9&scoped=true"
import script from "./tips2.vue?vue&type=script&lang=js"
export * from "./tips2.vue?vue&type=script&lang=js"
import style1 from "./tips2.vue?vue&type=style&index=1&id=144b78e9&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "144b78e9",
  null
  
)

export default component.exports