<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" custom-class="dialog-body-paddingTop-10" width="795px">
      <div class="flex">
        <div class="keyLabel start">商品范围：</div>
        <div>
          <el-radio-group v-model="ProductRangeType">
            <div style="margin-top:4px">
              <el-radio :label="1">全部商品</el-radio>
            </div>
            <div class="margin-top-20">
              <el-radio :label="2">指定商品属性</el-radio>
              <div class="font-12 margin-top-10" style="color:#999999FF;margin-left:25px">
                比如【题目：您属于哪一类肤色？】-【选项：白皙】，白皙肤色的人适合所有珍珠，选项关联商品范围【全部商品】
              </div>
            </div>
          </el-radio-group>
        </div>
      </div>
      <div class="margin-top-10" v-if="ProductRangeType==2">
        <div class="top">
          <el-input placeholder="搜索属性名称" v-model.trim="keywords" class="input-with-select" style="width:320px" @keyup.enter.native="query">
            <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
          </el-input>
          <div v-loading="tableloading" class="top-content margin-top-20" style="flex:1;">
            <div v-if="saarchType!=0">
              <div v-if="tableloading"></div>
              <div v-else>
                <div class="header">
                  <div v-for="v in level" :key="v">
                    {{levelfont[v-1]}}级属性
                  </div>
                </div>
                <el-cascader-panel :options="tablelistVice" :props="{ checkStrictly: true,multiple:true,label:'AttributeName',value:'Id',children:'LowerList',
                emitPath:true}" class="cascaderCls" @change="getshowStatsVice" v-model="choosedataVice" v-if="searchKey" key="haskey"></el-cascader-panel>
                <el-cascader-panel :options="tablelist" :props="{ checkStrictly: true,multiple:true,label:'AttributeName',value:'Id',children:'LowerList',
                emitPath:true}" class="cascaderCls" @change="getnamelist" v-model="choosedata" v-else ref="cascader" key="nokey"></el-cascader-panel>
              </div>
            </div>
            <div class="margin-top-10 flex flex-align-center flex-justify-center" style="height:240px;color:#999999" v-else>
              <span v-if="!searchKey">
                暂无属性，<el-button type="text" @click="topage">点击前往管理商品属性</el-button>
              </span>
              <span v-else>未查询到相关属性</span>
            </div>
          </div>
        </div>
        <div class="margin-top-20">
          <div class="font-16 font-bold">已选择属性：</div>
          <div class="choose margin-top-10">
            <div v-for="(v,i) in showchooselist" :key="i" class="infotag">
              <el-tag type="info" closable @close="handleClose(i)">
                <span class="nameSpan" v-for="(x,y) in v.namePath" :key="y">
                  {{x}} 
                </span>
              </el-tag>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button click="close" @click="close">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  productAttributeproductAttributeInit,
  productAttributeproductAttributeChild,
  productAttributeproductAttributeTree,
  questionOptionproductAttribute
} from "@/api/TurnTomySelf"
export default {
  props: {
    msg:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },

  data () {
    return {
      ProductRangeType:1,
      ProductAttributeIdList:[],
      keywords:'',
      tableloading:false,
      tablelist:[],
      tablelistVice:[],
      choosedata:[],
      choosedataVice:[],
      searchKey:false,
      level:4,
      levelfont:['一','二','三','四'],
      showchooselist:[],
      saarchType:0,
    }
  },
  async mounted () {
    this.ProductRangeType = this.msg.ProductRangeType||1
    // this.ProductAttributeIdList = this.msg.ProductAttributeIdList
    // console.log(this.msg.ProductAttributeIdList)
    // this.choosedata = this.msg.ProductAttributeIdList.map(v=>[v])
    // console.log(this.msg)
    await this.gettablelist()
    if(this.msg.Id){
      this.getAttributelist()
    }else{
      this.choosedata = this.msg.ProductAttributeIdListAll
      this.getnamelist()
    }
  },
  methods: {
    async getAttributelist(){
      try{
        this.tableloading = true
        let res = await questionOptionproductAttribute({
          TakeTestQuestionOptionId:this.msg.Id
        })
        if(res.IsSuccess){
          // console.log(this.msg.ProductAttributeIdListAll)
          if(this.msg.ProductAttributeIdListAll&&this.msg.ProductAttributeIdListAll.length){
            this.choosedata = this.msg.ProductAttributeIdListAll
          }else{
            this.choosedata = res.Result.filter(v=>{
              return v.ProductAttributeIdAll
            }).map(v=>{
              return v.ProductAttributeIdAll.split('|').map(x=>Number(x))
            })
          }
          // console.log(res.Result)
          // console.log(this.choosedata)
          this.getshowStatsVice()
        }
      }finally{
        this.tableloading = false
      }
    },
    close(){
      this.tablelist = []
      this.tablelistVice = []
      this.keywords = ''
      this.searchKey = false
      this.choosedata = []
      this.choosedataVice = []
      this.$emit('close')
    },
    confirm(){
      if(this.ProductRangeType==2&&(!this.choosedata||!this.choosedata.length)){
        this.$message.error('请选择商品属性')
        return
      }
      this.$emit('confirm',{
        ProductRangeType:this.ProductRangeType,
        ProductAttributeIdList:this.ProductRangeType==2?this.choosedata.map(v=>v[v.length-1]):[],
        ProductAttributeIdListAll:this.ProductRangeType==2?this.choosedata:[],
      })
    },
    handleClose(i){
      this.tableloading = true
      this.showchooselist.splice(i,1)
      this.choosedata.splice(i,1)
      setTimeout(()=>{
        this.tableloading = false
      },0)
    },
    getshowStatsVice(e){
      this.choosedataVice.map(v=>{
        if(!this.choosedata.some(x=>String(x)==String(v))){
          this.choosedata.push(v)
        }
      })
      this.getnamelist()
    },
    getnamelist(e){
      // console.log(this.choosedata)
      this.showchooselist = []
      this.choosedata = this.choosedata.filter(v=>{
        let res,res1,res2,res3
        res= this.tablelist.find(x=>x.Id==v[0])
        if(res&&res.LowerList&&res.LowerList.length&&v[1]){
          res1 = res.LowerList.find(x=>x.Id==v[1])
          if(res1&&res1.LowerList&&res1.LowerList.length&&v[2]){
            res2 = res1.LowerList.find(x=>x.Id==v[2])
            if(res2&&res2.LowerList&&res2.LowerList.length&&v[3]){
              res3 = res2.LowerList.find(x=>x.Id==v[3])
            }
          }
        }
        let msg = {
          idPath:v,
          namePath:[]
        }
        let allres = [res,res1,res2,res3].filter(z=>z)
        allres.map(z=>{
          if(z){
            msg.namePath.push(z.AttributeName)
          }
        })
        // console.log(allres)
        if(v.length==allres.length){
          this.showchooselist.push(msg)
        }
        return v.length==allres.length
      })
      // console.log(this.choosedata)
    },
    topage(){
      this.$router.push({
        path:'/goods/shopattribute'
      })
    },
    async gettablelist() {
      try {
        this.tableloading = true;
        let data = {
          Keywords:this.keywords,
          Skip:0,
          Take: 99999,
        };
        let res = await productAttributeproductAttributeTree(data);
        if (res.IsSuccess) {
          this.tablelist = await this.getexpanded(res.Result)
          if(this.tablelist&&this.tablelist.length){
            this.saarchType = 1
          }else{
            this.saarchType = 0
          }
          this.total = res.Result.Total;
          this.getshowStatsVice()
        }
      } finally {
        this.tableloading = false;
      }
    },
    getexpanded(list){
      return list.map(v=>{
        if(!v.LowerList||!v.LowerList.length){
          delete v.LowerList
        }else{
          v.LowerList = this.getexpanded(v.LowerList)
        }
        return v
      })
    },
    query(){
      this.currentPage = 1
      this.searchKey = Boolean(String(this.keywords))
      if(this.searchKey){
        this.getkeywordslist()
      }else{
        this.gettablelist()
      }
    },
    async getkeywordslist(){
      try {
        this.tableloading = true;
        let data = {
          Keywords:this.keywords,
          Skip:0,
          Take: 99999,
        };
        let res = await productAttributeproductAttributeInit(data);
        if (res.IsSuccess) {
          this.tablelistVice = this.getexpanded(res.Result.Results)
          if(this.tablelistVice&&this.tablelistVice.length){
            this.saarchType = 2
          }else{
            this.saarchType = 0
          }
        }
      } finally {
        this.tableloading = false;
      }
    },
  },

}
</script>

<style lang='less' scoped>
.choose{
  border: 1px solid #DCDFE6;
  // max-height: 200px;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content:flex-start;
  gap: 10px;
  .infotag{
    // border: 1px solid black;
    .nameSpan{
      font-size: 14px;
      color: #999999FF;
      &::after{
        content:'丨';
      }
      &:last-of-type{
        color: #606266FF !important;
        &::after{
          content:'';
        }
      }
    }
  }
}
.top{
  border: 1px solid #DCDFE6;
  min-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  .top-content{
    display: flex;
    flex-direction: column;
    .header{
      background: #FBFBFBFF;
      overflow: hidden;
      display: flex;
      align-items: center;
      width: 720px;
      div{
        display: flex;
        width:179px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 9px 0px;
        font-size: 14px;
        font-weight: bold;
      }
    }
    // .searchcascader{
    //   border: 1px solid #EEEEEE;
    //   border-radius: 0px 0px 0px 0px;
    //   padding: 16px 20px;
    //   height: 330px;
    //   overflow: hidden;
    //   overflow-y: auto;
    // }
    .cascaderCls{
      ::v-deep .el-cascader-node{
        max-width: 179px;
      }
      ::v-deep .el-cascader-menu__wrap{
        height: 250px;
        .in-checked-path{
          color:#409eff;
          // font-weight: 400;
        }
        .is-active{
          color: #409eff;
          // font-weight: bold;
        }
        .in-active-path{
          // font-weight: bold;
          color:#409eff;
        }
        .is-selectable{
          font-weight: 400;
        }
      }
    }
  }
  .content-center{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width:100%
  }
}

</style>