<template>
  <div>
    <div class="page">
      <div class="flex flex-align-center" :class="{pdTopShadow:hasshadow}">
        <el-button @click="push(1)">添加单选题</el-button>
        <el-button @click="push(2)">添加多选题</el-button>
        <el-button type="text" @click="imgshow=true">1个页面展示1个题目，点击查看题目展示示例</el-button>
      </div>
      <div class="scroll-list" @scroll="getscroll">
        <div class="font-12 margin-top-10" style="color:#999999FF;margin-bottom:5px">拖动题目可进行排序</div>
        <div v-if="form.TakeTestQuestionInfoList&&form.TakeTestQuestionInfoList.length">
          <draggable v-model="form.TakeTestQuestionInfoList" :options="{animation: 100, handle:'.dragimg'}">
            <div class="demo" v-for="(v,i) in form.TakeTestQuestionInfoList" :key="i">
              <div class="header">
                <div class="header-left font-14">
                  <div class="flex flex-align-center" style="margin-bottom:20px">
                    <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-draggable.png" class="dragimg" alt="">
                    <span>[{{v.QuestionType==1?'单':'多'}}]题目{{i+1}}：</span>
                  </div>
                  <el-form-item label-width="0" :prop="'TakeTestQuestionInfoList.'+i+'.Name'" :rules="rules.QName">
                    <el-input v-model="v.Name" style="width:300px" placeholder="请输入题目名称，最多输入60个字" maxlength="60"></el-input>
                  </el-form-item>
                </div>
                <div class="flex flex-align-center">
                  <el-button type="text" @click="zoom(i,v.show)">{{v.show?'收起':'展开'}}</el-button>
                  <el-button type="text" style="color:#f56c6c" @click="delQ(i)" v-if="form.TakeTestQuestionInfoList.length>1">删除</el-button>
                </div>
              </div>
              <div class="content" ref="content" :class="v.show?'':'packup'">
                <div class="inner">
                  <el-form-item label="选项类型：" required>
                    <el-radio-group v-model="v.OptionType">
                      <el-radio :label="1">普通选项</el-radio>
                      <el-radio :label="2">价格选项</el-radio>
                    </el-radio-group>
                    <div class="font-14" style="color:#999999FF">选中[价格选项]，无需关联商品属性，系统根据客户选中的价格区间范围，自动匹配相应商品</div>
                  </el-form-item>
                  <el-form-item label="选项格式：" required v-if="v.OptionType==1">
                    <el-radio-group v-model="v.OptionFormat">
                      <el-radio :label="1">文本</el-radio>
                      <el-radio :label="2">文本+图片</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item :label="`选项${y+1}：`" v-for="(x,y) in v.TakeTestQuestionOptionInfoList" :key="y" :required="true" class="lastoption">
                    <div class="flex flex-align-start">
                      <div class="flex flex-align-start margin-right-10" v-if="v.OptionType==1">
                        <el-form-item label-width="0" :prop="'TakeTestQuestionInfoList.'+i+'.TakeTestQuestionOptionInfoList.'+y+'.ImgUrl'" :rules="rules.ImgUrl"
                        v-if="v.OptionFormat==2" class="margin-right-10">
                          <el-upload :action="imgApi" accept="image/*" list-type="picture-card" :show-file-list="false"
                          :before-upload="beforeupload" :on-success="($event)=>{onsuccess($event,i,y)}" class="width60">
                            <div v-if="x.ImgUrl" class="flex flex-align-center" style="overflow-y:hidden;height:100%;width:100%">
                              <img :src="imgUrl+x.ImgUrl" style="width:100%" alt="">
                            </div>
                            <i class="el-icon-plus" v-else></i>
                          </el-upload>
                        </el-form-item>
                        <el-form-item label-width="0" :prop="'TakeTestQuestionInfoList.'+i+'.TakeTestQuestionOptionInfoList.'+y+'.Name'" :rules="rules.OName" class="margin-right-10">
                          <el-input v-model="x.Name" placeholder="请输入选项内容，最多输入20个字" maxlength="20" style="width:300px"></el-input>
                        </el-form-item>
                        <el-button type="text" v-if="v.OptionType==1" @click="relevance(x,i,y)">
                          {{x.ProductRangeType==0?'关联商品属性':
                          (x.ProductRangeType==1?'已关联全部商品':
                          `已关联${x.ProductAttributeIdListAll.length||x.ProductAttributeIdList.length}个属性`)}}
                        </el-button>
                      </div>
                      <el-form-item label-width="0" :prop="'TakeTestQuestionInfoList.'+i+'.TakeTestQuestionOptionInfoList.'+y+'.price'"
                        :rules="rules.price" v-else>
                        <div class="flex flex-align-center margin-right-10">
                          <el-input style="width:200px" v-model="x.MinPrice" placeholder="最小价格"
                          onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                          onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                          <span style="margin:0px 10px">~</span>
                          <el-input style="width:200px" v-model="x.MaxPrice" placeholder="最大价格"
                          onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                          onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                          <span style="margin:0px 10px">元</span>
                        </div>
                      </el-form-item>
                      <el-button type="text" style="color:#f56c6c" @click="del(i,y)" v-if="v.TakeTestQuestionOptionInfoList.length>2">删除</el-button>
                    </div>
                  </el-form-item>
                </div>
                
                <el-button type="text" style="margin-left:170px;margin-bottom:20px" v-if="v.TakeTestQuestionOptionInfoList.length<10" @click="addOption(i)">+ 添加选项</el-button>
              </div>
            </div>
          </draggable>
        </div>
        <div class="nolist" v-else>
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-click.png" style="width:20px;height:20px" alt="">
          <div class="font-14" style="color:#606266FF">点击上方按钮添加单选题/多选题</div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="imgshow" title="题目展示示例图" width="1000px">
      <div class="flex font-14 flex-justify-between" style="color:#606266FF">
        <div>
          <div class="margin-bottom-10">选项-仅文本</div>
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-showimg3.png" style="width:200px" alt="">
        </div>
        <div>
          <div class="margin-bottom-10">选项-文本+图片格式(1)</div>
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-showimg2.png" style="width:200px" alt="">
        </div>
        <div>
          <div class="margin-bottom-10">选项-文本+图片格式(2)</div>
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-showimg1.png" style="width:200px" alt="">
        </div>
        <div>
          <div class="margin-bottom-10">选项-价格类型</div>
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/takeTest-showimg4.png" style="width:200px" alt="">
        </div>
      </div>
    </el-dialog>

    <attribute v-if="diaShow" :visible.sync="diaShow" title="选项关联商品属性" top="7vh" @close="diaShow=false" @confirm="getshopAttribute"
      :msg="optMsg"></attribute>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import config from '@/config/index';
import attribute from './attribute';
export default {
  components:{
    draggable,
    attribute
  },
  props: {
    form:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    rules:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  data () {
    return {
      imgApi: config.UPLOAD_IMG,
      imgUrl: config.IMG_BASE,
      activeName:[],
      diaShow:false,
      imgshow:false,
      optMsg:{},
      coordinate:[],
      hasshadow:false,
    }
  },
  methods: {
    getscroll(e){
      this.hasshadow = e.target.scrollTop!=0
    },
    getshopAttribute(e){
      let [i,y] = this.coordinate
      this.form.TakeTestQuestionInfoList[i].TakeTestQuestionOptionInfoList[y].ProductRangeType = e.ProductRangeType
      this.form.TakeTestQuestionInfoList[i].TakeTestQuestionOptionInfoList[y].ProductAttributeIdList = e.ProductAttributeIdList
      this.form.TakeTestQuestionInfoList[i].TakeTestQuestionOptionInfoList[y].ProductAttributeIdListAll = e.ProductAttributeIdListAll
      this.diaShow = false
      this.$emit('change',{
        key:'TakeTestQuestionInfoList',
        value:this.form.TakeTestQuestionInfoList
      })
    },
    relevance(x,i,y){
      this.optMsg = x
      this.coordinate = [i,y]
      this.diaShow = true
    },
    delQ(i){
      this.$confirm('删除后不可恢复，是否确认删除题目?', '提示', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.TakeTestQuestionInfoList.splice(i,1)
      })
    },
    zoom(i,show){
      this.form.TakeTestQuestionInfoList[i].show = !this.form.TakeTestQuestionInfoList[i].show
    },
    addOption(i){
      let list = JSON.parse(JSON.stringify(this.form.TakeTestQuestionInfoList))
      list[i].TakeTestQuestionOptionInfoList.push({
        ImgUrl:'',
        Name:'',
        MinPrice:'',
        MaxPrice:'',
        ProductRangeType:0,
        ProductAttributeIdList:[],
        ProductAttributeIdListAll:[]
      })
      this.$emit('change',{
        key:'TakeTestQuestionInfoList',
        value:list
      })
    },
    del(i,y){
      let list = JSON.parse(JSON.stringify(this.form.TakeTestQuestionInfoList))
      list[i].TakeTestQuestionOptionInfoList.splice(y,1)
      this.$emit('change',{
        key:'TakeTestQuestionInfoList',
        value:list
      })
      this.activeName.filter(v=>{
        return v!=i
      })
    },
    push(type){
      if(this.form.TakeTestQuestionInfoList&&this.form.TakeTestQuestionInfoList.length>=50){
        this.$message.error('最多可添加50个题目')
        return
      }
      let data = {
        QuestionType:type,
        Name:'',
        OptionType:1,
        OptionFormat:1,
        show:true,
        TakeTestQuestionOptionInfoList:[
          {
            ImgUrl:'',
            Name:'',
            MinPrice:'',
            MaxPrice:'',
            ProductRangeType:0,
            ProductAttributeIdList:[],
            ProductAttributeIdListAll:[]
          },
          {
            ImgUrl:'',
            Name:'',
            MinPrice:'',
            MaxPrice:'',
            ProductRangeType:0,
            ProductAttributeIdList:[],
            ProductAttributeIdListAll:[]
          },
        ],
      }
      let list = [...this.form.TakeTestQuestionInfoList,data]
      this.$emit('change',{
        key:'TakeTestQuestionInfoList',
        value:list
      })
      this.activeName.push(list.length-1)
    },
    onsuccess(file,i,y){
      let list = JSON.parse(JSON.stringify(this.form.TakeTestQuestionInfoList))
      list[i].TakeTestQuestionOptionInfoList[y].ImgUrl = file[0]
      // console.log(list)
      this.$emit('change',{
        key:'TakeTestQuestionInfoList',
        value:list
      })
    },
    beforeupload(file){
      const size = file.size / 1024 / 1024 < 1;
      const testtype = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg']
      // console.log(file.type)
      if (testtype.indexOf(file.type) == -1) {
        this.$message.error('请选择图片上传');
        return false
      } else if (!size) {
        this.$message.error('图片大小请控制在1M以内');
        return false
      }
    },
  }
}
</script>
<style lang='less'>

</style>
<style lang='less' scoped>
.pdTopShadow{
  box-shadow: 0 8px 12px -5px rgba(0,0,0,.1);
  // border:1px solid black
}
.lastoption{
  &:last-child{
    margin-bottom: 10px;
  }
}
.scroll-list{
  height: calc(100vh - 340px);
  overflow-y: auto;
  // border: 1px solid black;
  padding-top: 10px;
  z-index: 100;
}
.width60{
  ::v-deep .el-upload--picture-card{
    width:90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.nolist{
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #DCDFE6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.demo{
  border: 1px solid #EBEEF5;
  margin-bottom:10px;
  z-index: 99;
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F8F8F9;
    padding: 16px 10px 0px 10px;
    .header-left{
      display: flex;
      align-items: center;
      .dragimg{
        cursor: move;
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }
    }
  }
  .content{
    overflow: hidden;
    transition: all .1s linear;
    max-height: 10000px;
    .inner{
      padding: 10px 50px 0px 50px;
      
    }
  }
  .packup{
    max-height: 0px;
  }
}
.diycollapse{
  ::v-deep .el-collapse-item__header{
    height: auto;
    width: 100%;
    pointer-events: none;
  }
  ::v-deep .el-collapse-item__arrow{
    width: 0;
    display: none;
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F8F8F9;
    padding: 16px 10px 0px 10px;
    flex: 1;
    .header-left{
      display: flex;
      align-items: center;
      .dragimg{
        cursor: move;
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }
    }
  }
}
</style>